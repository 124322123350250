var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Recuperación de contraseña")])]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'password',
							{
								rules: [
									{ required: true, message: 'Campo requerido.' },
									{
										min: 6,
										message: 'Ingrese contraseña de al menos 6 caracteres.',
									},
									{
										validator: _vm.validateToNextPassword,
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true, message: 'Campo requerido.' },\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Ingrese contraseña de al menos 6 caracteres.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tvalidator: validateToNextPassword,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Ingresa nueva contraseña","autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"Confirma tu contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'confirmPassword',
							{
								rules: [
									{ required: true, message: 'Campo requerido.' },
									{
										min: 6,
										message: 'Ingrese contraseña de al menos 6 caracteres.',
									},
									{
										validator: _vm.compareToFirstPassword,
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'confirmPassword',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true, message: 'Campo requerido.' },\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Ingrese contraseña de al menos 6 caracteres.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tvalidator: compareToFirstPassword,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Confirma tu nueva contraseña","autocomplete":"off"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"size":"large","type":"primary","htmlType":"submit"}},[_c('strong',[_vm._v("Enviar")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_c('i',{staticClass:"fe fe-arrow-left mr-1 align-middle"}),_vm._v(" Regresar a inicio de sesión ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }